import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

interface ComparativeSideBySideBarChartProps {
    data: { [key: string]: { [key: string]: number } }[];
}

const ComparativeSideBySideBarChart: React.FC<ComparativeSideBySideBarChartProps> = ({ data }) => {
    const chartData = data.map(entry => {
        const [year, values] = Object.entries(entry)[0];
        return { year, ...values };
    });

    const subcategories = Object.keys(chartData[0]).filter(key => key !== 'year');

    return (
        <ResponsiveContainer width="100%" height={500}>
            <BarChart
                data={chartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Legend />
                {subcategories.map((subcategory, index) => (
                    <Bar key={subcategory} dataKey={subcategory} stackId={`subcategory-${index}`} fill={`#${((Math.random() * 0xffffff) << 0).toString(16)}`} />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default ComparativeSideBySideBarChart;
