import { createBrowserRouter, redirect } from "react-router-dom";
import Layout from "../layout/Layout";
import DataAnalysisWithFile from "../screens/DataAnalysisWithFile"
import IconPack from "../screens/IconPack";

const router = createBrowserRouter([
    {
        path: "/",
        index: true,
        element: <></>,
        loader: async () => {
            return redirect("/data-analysis-with-file");
        },
    },
    {
        path: "/",
        element: (
            <Layout />
        ),
        children: [
            {
                path: "/data-analysis-with-file",
                element: <DataAnalysisWithFile />,
            },
            {
                path: "/icon-pack",
                element: <IconPack />,
            }
        ],
    },
]);

export default router;
