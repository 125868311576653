import './App.css';
import React from 'react'
import { RouterProvider } from "react-router-dom";
import './App.css'
import { ConfigProvider } from "antd";
import router from "./routes/routes"

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Roboto',
          colorPrimary: "#173753",
          colorLink: '#173753',
          colorLinkActive: '#3c3c3c',
          colorLinkHover: '#3c3c3c',
          colorError: "#c00",
          colorBorder: "#949494",
          borderRadius: 4,
          fontSize: 14,
        },
      }}
    >
      <React.Suspense fallback={<p>Loading...</p>}>
        <RouterProvider router={router} />
      </React.Suspense>
    </ConfigProvider>
  );
}

export default App;
