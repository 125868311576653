import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

interface GrowthBarChartProps {
    data: Record<string, number>;
}

const GrowthBarChart: React.FC<GrowthBarChartProps> = ({ data }) => {
    const dataEntries = Object.entries(data);

    return (
        <ResponsiveContainer width="100%" height={500}>
            <BarChart
                data={dataEntries.map(([year, value]) => ({
                    year,
                    value,
                }))}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default GrowthBarChart;
