import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width=""
      height=""
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill="#0F1729" d="M8.5 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
      <path
        fill="#0F1729"
        fillRule="evenodd"
        d="M11.005 2h1.99c1.386 0 2.488 0 3.377.074.91.075 1.686.234 2.394.602a6 6 0 012.559 2.558c.367.709.526 1.484.601 2.394.074.89.074 1.991.074 3.377v1.99c0 .69 0 1.311-.01 1.87.015.103.013.208-.005.31-.01.437-.029.835-.059 1.197-.075.91-.233 1.686-.601 2.394a6 6 0 01-2.56 2.559c-.707.367-1.483.526-2.393.601-.89.074-1.992.074-3.377.074h-1.99c-1.385 0-2.488 0-3.377-.074-.91-.075-1.685-.233-2.394-.601a6 6 0 01-2.558-2.56c-.368-.707-.527-1.483-.602-2.393C2 15.482 2 14.38 2 12.995v-1.99c0-1.386 0-2.488.074-3.377.075-.91.234-1.685.602-2.394a6 6 0 012.558-2.558c.709-.368 1.484-.527 2.394-.602C8.518 2 9.62 2 11.005 2zM20 11.05v1.462l-1.387-1.447a2 2 0 00-2.895.008l-4.667 4.92-1.536-1.857a2 2 0 00-3.135.067l-2.19 2.89a6.877 6.877 0 01-.123-.887C4 15.41 4 14.39 4 12.95v-1.9c0-1.44 0-2.46.067-3.256.065-.784.188-1.263.383-1.638A4 4 0 016.156 4.45c.375-.195.854-.318 1.638-.383C8.59 4 9.609 4 11.05 4h1.9c1.44 0 2.46 0 3.256.067.785.065 1.263.188 1.638.383a4 4 0 011.706 1.706c.195.375.318.854.383 1.638C20 8.59 20 9.609 20 11.05zm-13.844 8.5a4.002 4.002 0 01-.861-.603l2.68-3.536 1.535 1.857a2 2 0 002.992.101l4.667-4.92 2.81 2.93c-.01.302-.025.576-.046.827-.065.785-.188 1.263-.383 1.638a4 4 0 01-1.706 1.706c-.375.195-.854.318-1.638.383C15.41 20 14.39 20 12.95 20h-1.9c-1.44 0-2.46 0-3.256-.067-.784-.065-1.263-.188-1.638-.383z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;