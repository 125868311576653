import axios from "axios";

const baseURL = 'https://iconpackgen.digitalavenues.net'
const service = axios.create({ baseURL });

service.interceptors.request.use(async (config: any) => {
    // const bearer = `Bearer ${access_token}`;
    // config.headers.Authorization = bearer;
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";

    return config;
},
    (error) => {
        return Promise.reject(error);
    }
);



export { service };