import React from 'react';

interface AdditionalInsightsProps {
    additionalInsights: {
        expense_management?: {
            deterioration_years: string[];
            improvement_years: string[];
            total_expense_reduction: string;
        };
        operational_efficiency_improvement?: {
            net_operating_income_growth: string;
        };
        other_income_and_expense_fluctuations?: {
            other_expense: {
                [key: string]: number;
            };
            other_income: {
                [key: string]: number;
            };
        };
        significant_increases_and_decreases?: {
            gross_profit_increase_2018_to_2023: string;
            net_income_fluctuation: string;
        };
        significant_other_income_impact?: {
            [key: string]: string;
            description: string;
        }[];
        stable_performers?: {
            [key: string]: string;
        };
    };
}

const AdditionalInsights: React.FC<AdditionalInsightsProps> = ({ additionalInsights }) => {
    return (
        <div className='card'>
            <h1>Additional Insights</h1>

            {additionalInsights.expense_management && (
                <div className='card'>
                    <h3>Expense Management</h3>
                    {additionalInsights.expense_management.deterioration_years.length > 0 && (
                        <p><strong>Deterioration Years :</strong> {additionalInsights.expense_management.deterioration_years.join(', ')}</p>
                    )}
                    {additionalInsights.expense_management.improvement_years.length > 0 && (
                        <p><strong>Improvement Years :</strong> {additionalInsights.expense_management.improvement_years.join(', ')}</p>
                    )}
                    {additionalInsights.expense_management.total_expense_reduction && (
                        <p><strong>Total Expense Reduction :</strong> {additionalInsights.expense_management.total_expense_reduction}</p>
                    )}
                </div>
            )}

            {additionalInsights.operational_efficiency_improvement && (
                <div className='card'>
                    <h3>Operational Efficiency Improvement</h3>
                    <p>{additionalInsights.operational_efficiency_improvement.net_operating_income_growth}</p>
                </div>
            )}

            {additionalInsights.other_income_and_expense_fluctuations && (
                <div className='card'>
                    <h3>Other Income and Expense Fluctuations</h3>
                    <div className='card'>
                        <h4>Other Expense</h4>
                        <ul style={{ marginLeft: '20px' }}>
                            {Object.entries(additionalInsights.other_income_and_expense_fluctuations.other_expense).map(([year, value]) => (
                                <li key={year}><strong>{year} :</strong> {value}</li>
                            ))}
                        </ul>
                    </div>
                    <div className='card'>
                        <h4>Other Income</h4>
                        <ul style={{ marginLeft: '20px' }}>
                            {Object.entries(additionalInsights.other_income_and_expense_fluctuations.other_income).map(([year, value]) => (
                                <li key={year}><strong>{year} :</strong> {value}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

            {additionalInsights.significant_increases_and_decreases && (
                <div className='card'>
                    <h3>Significant Increases and Decreases</h3>
                    {Object.entries(additionalInsights.significant_increases_and_decreases).map(([key, value]) => (
                        <p key={key}><strong>{key.replace(/_/g, ' ').toUpperCase()} :</strong> {value}</p>
                    ))}
                </div>
            )}

            {additionalInsights.significant_other_income_impact && (
                <div className='card'>
                    <h3>Significant Other Income Impact</h3>
                    {additionalInsights.significant_other_income_impact.map((impact, index) => (
                        <div key={index}>
                            <p>{Object.entries(impact).map(([year, year_description]) => (
                                <p style={{ marginRight: '20px' }} key={year}><strong>{year} :</strong> {year_description}</p>
                            ))}</p>
                        </div>
                    ))}
                </div>
            )}

            {additionalInsights.stable_performers && (
                <div className='card'>
                    <h3>Stable Performers</h3>
                    {Object.entries(additionalInsights.stable_performers).map(([performer, description], index) => (
                        <p key={index}><strong>{performer} :</strong> {description}</p>
                    ))}
                </div>
            )}

        </div>
    );
};

export default AdditionalInsights;
