// Layout.jsx
import { Outlet } from "react-router-dom";
import DataCategoryFooter from "./Footer";
import DataCategoryHeader from "./Header";
import "../assets/scss/custom-style.css"

const Layout = () => {
    return (
        <>
            <DataCategoryHeader />
            <main className="mainContent">
                <div className="contentWrap">
                    <Outlet />
                </div>
                <DataCategoryFooter />
            </main>
        </>
    );
};

export default Layout;
