import React, { useState } from "react"
import { Modal } from "antd"
// import "../assets/style/main.css"
import { CopyOutlined, DownSquareOutlined } from "@ant-design/icons"
import { toast } from "react-toastify"



type Props = {
    image: string
    toggle?: boolean
}
const ImageModal = ({ image, toggle }: Props) => {

    function Copy() {
        const input: any = document.createElement('input');
        document.body.appendChild(input);
        input.value = image
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        toast.success('Link copied')
    }

    return (
        <React.Fragment>
            <div className=" flex flex-col gap-20">
                <img className="modal-img" src={image} alt="generated icon" />
                <div className="image-url">
                    <CopyOutlined onClick={Copy} />{image}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ImageModal