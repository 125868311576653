import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

interface RatioLineChartProps {
    data: Record<string, number>;
}

const RatioLineChart: React.FC<RatioLineChartProps> = ({ data }) => {
    const dataEntries = Object.entries(data);

    return (
        <ResponsiveContainer width="100%" height={500}>
            <LineChart
                data={dataEntries.map(([year, value]) => ({
                    year,
                    value,
                }))}
                margin={{ top: 20, right: 40, left: 40, bottom: 20 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default RatioLineChart;
