import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Cell, // Import Cell from recharts
} from 'recharts';

interface DataBarChartProps {
    data: Record<string, string>;
}

const DataBarChart: React.FC<DataBarChartProps> = ({ data }) => {
    const dataEntries = Object.entries(data);
    const colors = [
        '#393b79', '#5254a3', '#6b6ecf', '#9c9ede', '#637939',
        '#8ca252', '#b5cf6b', '#cedb9c', '#8c6d31', '#bd9e39',
        '#e7ba52', '#e7cb94', '#843c39', '#ad494a', '#d6616b',
        '#e7969c', '#7b4173', '#a55194', '#ce6dbd', '#de9ed6'
    ];

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                data={dataEntries.map(([category, value]) => ({
                    category,
                    value: parseFloat(value),
                }))}
                margin={{ top: 20, right: 40, left: 50, bottom: 80 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="category"
                    angle={45}
                    textAnchor="start"

                />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value">
                    {dataEntries.map(([category], index) => (
                        <Cell key={category} fill={colors[index % colors.length]} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default DataBarChart;
