import { Layout } from "antd";
import "../assets/scss/custom-style.css"
const { Footer } = Layout;

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const ResumeFooter = () => {
    return <Footer className="footer">
        <p><b>&copy; {currentYear} <a href="https://www.digitalavenues.com/">Digital Avenues Infotech Private Limited.</a></b></p>
        <p><a href="https://www.digitalavenues.com/privacy-policy">Privacy Policy</a> | <a href="https://www.digitalavenues.com/terms">Terms of Service</a></p>
    </Footer>;
};

export default ResumeFooter;
