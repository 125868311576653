import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Space, message, Upload, Button, Input, Radio, Divider } from 'antd';
import axios from 'axios';
import "../assets/scss/custom-style.css";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import DataPieChart from './DataPieChart';
import DataBarChart from './DataBarChart';
import CompositionStackedBarChart from './CompositionStackedBarChart';
import BulletChart from './BulletChart';
import ComparativeGroupedBarChart from './ComparativeGroupedBarChart';
import DataLineChart from './DataLineChart';
import GrowthBarChart from './GrowthBarChart';
import RatioLineChart from './RatioLineChart';
import ComparativeSideBySideBarChart from './ComparativeSideBySideBarChart';
import AdditionalInsights from './AdditionalInsights';

const DataAnalysisWithFile: React.FC = () => {

    const { Dragger } = Upload;
    const [loading, setLoading] = useState(false);
    const [apiDataDivStatus, setApiDataDivStatus] = useState(false);
    const [apiData, setApiData] = useState<any>();
    const [inputType, setInputType] = useState<'file' | 'text'>('file');
    const [textInput, setTextInput] = useState('');
    const { TextArea } = Input;

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextInput(e.target.value);
    };

    const handleInputTypeChange = (e: any) => {
        setInputType(e.target.value);
    };

    const handleFileUpload = async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        console.log("file", file)
        try {
            setLoading(true);
            console.log("first")
            const response = await axios.post(
                'https://us-central1-da-labs-398108.cloudfunctions.net/fn_openai_data_analysis_from_files_and_text',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            console.log(response);
            const DataAnalysis = response.data.final_data_analysis_result;
            console.log("Data analysis", DataAnalysis)
            setApiData(DataAnalysis)
            if (DataAnalysis) {
                setApiDataDivStatus(true);
            }
        }
        catch (error: any) {
            console.error('Error uploading file:', error);
            message.error('File upload failed.');
        }
        finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = await axios.post(
                'https://us-central1-da-labs-398108.com',
                {
                    data: {
                        feedback_file_content: textInput,
                    },
                }
            );
            const DataAnalysis = response.data.final_data_analysis_result;
            setApiData(DataAnalysis);
            if (DataAnalysis) {
                setApiDataDivStatus(true);
            }
        } catch (error: any) {
            console.error('Error sending text data:', error);
            message.error('Failed to send text data.');
        } finally {
            setLoading(false);
        }
    };


    const props: UploadProps = {
        name: 'file',
        multiple: false,
        action: '',
        customRequest: ({ file }: { file: any }) => {
            handleFileUpload(file);
        },
        method: "post",
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
            info.fileList.filter((x) => x.status = "done");
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };


    return (
        <>
            <Space style={{ width: '100%' }} direction="vertical">
                <Radio.Group value={inputType} onChange={handleInputTypeChange}>
                    <Radio value="file">Profit and Loss</Radio>
                    {/* <Radio value="text">Insert Text</Radio> */}
                </Radio.Group>
                {inputType === 'file' ? (
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single ".pdf", ".csv", ".xlsx", ".docx", ".doc" or ".txt" file upload. Strictly prohibited from uploading company data or other
                            banned files.
                        </p>
                    </Dragger>
                ) : (
                    <div>
                        <TextArea
                            rows={4}
                            placeholder="Enter text here..."
                            value={textInput}
                            onChange={handleInputChange}
                        />
                        <Button type="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                )}
                {loading ?
                    <Spin className='loader' indicator={<LoadingOutlined ></LoadingOutlined>}></Spin> : ""}
            </Space>
            <div className='container-style'>
                {apiDataDivStatus ?
                    <div>
                        <div className='card-container'>
                            {apiData.map((item: any, index: number) => (
                                <div className='card' key={index}>
                                    {item.analysis_overview && <div className='card'>
                                        <h1>Analysis Overview</h1>
                                        <p style={{ fontSize: '20px' }}>{item.analysis_overview}</p>
                                    </div>
                                    }
                                    {item.trends_over_time && <div className='card'>
                                        <h1>Trends Over Time</h1>
                                        <div className="chart-container">
                                            {item.trends_over_time.total_revenues && (
                                                <div className='over-time-chart-size'>
                                                    <h3>Total Revenues</h3>
                                                    <DataBarChart data={item.trends_over_time.total_revenues} />
                                                    <hr />
                                                </div>
                                            )}
                                            {item.trends_over_time.total_cost_of_goods_sold && (
                                                <div className='over-time-chart-size'>
                                                    <h3>Total Cost of Goods Sold</h3>
                                                    <DataBarChart data={item.trends_over_time.total_cost_of_goods_sold} />
                                                    <hr />
                                                </div>
                                            )}
                                            {item.trends_over_time.revenue_change_by_year && (
                                                <div className='over-time-chart-size'>
                                                    <h3>Revenue Change by Year</h3>
                                                    <DataBarChart data={item.trends_over_time.revenue_change_by_year} />
                                                    <hr />
                                                </div>
                                            )}
                                            {item.trends_over_time.net_income && (
                                                <div className='over-time-chart-size'>
                                                    <h3>Net Income</h3>
                                                    <DataBarChart data={item.trends_over_time.net_income} />
                                                    <hr />
                                                </div>
                                            )}
                                            {item.trends_over_time.gross_profit && (
                                                <div className='over-time-chart-size'>
                                                    <h3>Gross Profit</h3>
                                                    <DataBarChart data={item.trends_over_time.gross_profit} />
                                                    <hr />
                                                </div>
                                            )}
                                        </div>
                                    </div>}
                                    {item.composition_analysis && (
                                        <div className='card'>
                                            <h1>Composition Analysis</h1>
                                            {item.composition_analysis.expense_compositions && (
                                                <div>
                                                    <h3>Expense Compositions</h3>
                                                    <CompositionStackedBarChart data={item.composition_analysis.expense_compositions} title="Expense Compositions" />
                                                    <hr />
                                                </div>
                                            )}
                                            {item.composition_analysis.revenue_compositions && (
                                                <div>
                                                    <h3>Revenue Compositions</h3>
                                                    <CompositionStackedBarChart data={item.composition_analysis.revenue_compositions} title="Revenue Compositions" />
                                                    <hr />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {item.financial_ratio_analysis && <div className='card'>
                                        <h1>Financial Ratio Analysis</h1>
                                        <div className="chart-container">
                                            {item.financial_ratio_analysis.cost_of_goods_sold && (
                                                <div className='ratio-chart-size'>
                                                    <h3>Cost of Goods Sold (COGS)</h3>
                                                    <RatioLineChart data={item.financial_ratio_analysis.cost_of_goods_sold} />
                                                </div>
                                            )}
                                            {item.financial_ratio_analysis.gross_profit_margin && (
                                                <div className='ratio-chart-size'>
                                                    <h3>Gross Profit Margin</h3>
                                                    <RatioLineChart data={item.financial_ratio_analysis.gross_profit_margin} />
                                                </div>
                                            )}
                                            {item.financial_ratio_analysis.net_profit_margin && (
                                                <div className='ratio-chart-size'>
                                                    <h3>Net Profit Margin</h3>
                                                    <RatioLineChart data={item.financial_ratio_analysis.net_profit_margin} />
                                                </div>
                                            )}
                                            {item.financial_ratio_analysis.operating_expense_ratio && (
                                                <div className='ratio-chart-size'>
                                                    <h3>Operating Expense Ratio</h3>
                                                    <RatioLineChart data={item.financial_ratio_analysis.operating_expense_ratio} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    }
                                    {item.growth_rate_analysis && <div className='card'>
                                        <h1>Growth Rate Analysis</h1>
                                        <div className="chart-container">
                                            {item.growth_rate_analysis.gross_profit_growth_rate && (
                                                <div className='growth-chart-size'>
                                                    <h3>Gross Profit Growth Rate</h3>
                                                    <GrowthBarChart data={item.growth_rate_analysis.gross_profit_growth_rate} />
                                                </div>
                                            )}
                                            {item.growth_rate_analysis.net_income_growth_rate && (
                                                <div className='growth-chart-size'>
                                                    <h3>Net Income Growth Rate</h3>
                                                    <GrowthBarChart data={item.growth_rate_analysis.net_income_growth_rate} />
                                                </div>
                                            )}
                                            {item.growth_rate_analysis.revenue_growth_rate && (
                                                <div className='growth-chart-size'>
                                                    <h3>Revenue Growth Rate</h3>
                                                    <GrowthBarChart data={item.growth_rate_analysis.revenue_growth_rate} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    }
                                    {item.comparative_analysis && <div className='card'>
                                        <h1>Comparative Analysis</h1>
                                        {item.comparative_analysis.cogs_increase_vs_revenue_increase_vs_expense_increase_vs_income_increase && (
                                            <div>
                                                <h3>COGS, Expense, Income, and Revenue Increase/Decrease</h3>
                                                <ComparativeSideBySideBarChart data={item.comparative_analysis.cogs_increase_vs_revenue_increase_vs_expense_increase_vs_income_increase} />
                                            </div>
                                        )}
                                        {item.comparative_analysis.cogs_vs_revenue_vs_income_vs_expense && (
                                            <div>
                                                <h3>COGS, Expense, Income, and Revenue for Specific Years</h3>
                                                <ComparativeSideBySideBarChart data={item.comparative_analysis.cogs_vs_revenue_vs_income_vs_expense} />
                                            </div>
                                        )}
                                    </div>

                                    }
                                    {item.additional_insights && <AdditionalInsights additionalInsights={item.additional_insights} />}
                                </div>
                            ))}
                        </div>
                    </div> : ""}
            </div>
        </>
    )
};

export default DataAnalysisWithFile;


