import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

interface CompositionStackedBarChartProps {
    data: { [key: string]: { [key: string]: number } }[];
    title: string;
}

const CompositionStackedBarChart: React.FC<CompositionStackedBarChartProps> = ({ data, title }) => {
    const categories = data.map(entry => Object.keys(entry)[0]);
    const subcategories = Object.keys(data[0][categories[0]]);

    const chartData = categories.map(category => {
        const entry = data.find(item => Object.keys(item)[0] === category);
        if (!entry) return null; // Check if entry is undefined and return early
        return {
            category,
            ...entry[category],
        };
    }).filter(Boolean); // Remove null entries

    return (
        <ResponsiveContainer width="100%" height={500}>
            <BarChart
                data={chartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip />
                <Legend />
                {subcategories.map((subcategory, index) => (
                    <Bar key={subcategory} dataKey={subcategory} stackId={`subcategory-${index}`} fill={`#${((Math.random() * 0xffffff) << 0).toString(16)}`} />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CompositionStackedBarChart;
